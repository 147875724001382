import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { routes } from './routes';
import { VirtualArtGalleryComponent } from './virtual-art-gallery/virtual-art-gallery.component';
import { VirtualGalleryDemoComponent } from './virtual-gallery-demo/virtual-gallery-demo.component';


@NgModule({
  declarations: [VirtualArtGalleryComponent, VirtualGalleryDemoComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    FormsModule
  ],
  exports: [
  	RouterModule
  ],
  providers: []
})
export class RoutesModule { }
