import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class APIGetFactoryService {

  apiURL: string = environment.apiEndPoint;

  constructor(public http: HttpClient) { }

  public simpleOneGet(tableName: string, data: any) {
    console.log(" simpleOneGet sess", localStorage.getItem('lb4b-token'))
    console.log(" simpleOneGet tableName", tableName, "controlMethod", "data", data)
     if (localStorage.getItem('jwToken') && !localStorage.getItem('lb4b-token')) {
      console.log("inside get pkce-jwt from local storage to pass it as a header...")
      console.log("pkce-jwt:", localStorage.getItem('jwToken'))
      const headers = new HttpHeaders({
        'content-type': 'applicaton/json',
        'authorization': 'Bearer ' + localStorage.getItem('jwToken')
      })
      return this.http.get(`${this.apiURL}/${tableName}`, { headers: headers, params: data })
    }
    if (localStorage.getItem('lb4b-token') && localStorage.getItem('lb4b-token') != 'null') {
      let t = atob(JSON.parse(localStorage.getItem('lb4b-token'))['access_token'].split('.')[1])
      console.log(JSON.parse(t))
      t = JSON.parse(t)["iss"].split("/")
      console.log(t)
      let token = JSON.parse(localStorage.getItem('lb4b-token')).access_token
      console.log("GETfactrToken", token)
      const headers = new HttpHeaders({
        'content-type': 'applicaton/json',
        'authorization': 'Bearer ' + token,
        'realmName': t[t.length - 1]
      })
      return this.http.get(`${this.apiURL}/${tableName}`, { headers: headers, params: data })
    }
    return this.http.get(`${this.apiURL}/${tableName}`, { params: data })
  }

  public customGet(tableName: string, controlMethod: string, data: any) {
    console.log("sess", localStorage.getItem('lb4b-token'))
    console.log("tableName", tableName, "controlMethod", controlMethod, "data", data)
    if (localStorage.getItem('jwToken') && !localStorage.getItem('lb4b-token')) {
      console.log("inside get pkce-jwt from local storage to pass it as a header...")
      console.log("pkce-jwt:", localStorage.getItem('jwToken'))
      const headers = new HttpHeaders({
        'content-type': 'applicaton/json',
        'authorization': 'Bearer ' + localStorage.getItem('jwToken')
      })
      return this.http.get(`${this.apiURL}/${tableName}/${controlMethod}`, { headers: headers, params: data })
    }
    if (localStorage.getItem('lb4b-token') && localStorage.getItem('lb4b-token') != 'null') {
      console.log("inside get kc token from local storage to pass it as a header...")
      console.log("parse1:",JSON.parse(localStorage.getItem('lb4b-token')));
      let vc=JSON.parse(localStorage.getItem('lb4b-token'))
      console.log("parse2:",JSON.parse(vc).access_token)
      let t = atob(JSON.parse(JSON.parse(localStorage.getItem('lb4b-token')))['access_token'].split('.')[1])
      console.log(JSON.parse(t))
      t = JSON.parse(t)["iss"].split("/")
      console.log(t)
      let token = JSON.parse(JSON.parse(localStorage.getItem('lb4b-token'))).access_token
      console.log("GETfactrToken", token)
      const headers = new HttpHeaders({
        'content-type': 'applicaton/json',
        'authorization': 'Bearer ' + token,
        'realmName': t[t.length - 1]
      })
      return this.http.get(`${this.apiURL}/${tableName}/${controlMethod}`, { headers: headers, params: data })
    }
    return this.http.get(`${this.apiURL}/${tableName}/${controlMethod}`, { params: data })
  }

}
