import { Injectable }  from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class AppInitService {
    apiURL :string = environment.apiEndPoint;
    constructor(private http: HttpClient) {
    }
    Init() {
        // let myStorage = window.localStorage;
        return new Promise<void>((resolve, reject) => {
            console.log("AppInitService.init() called");
            ////do your initialisation stuff here
            localStorage.removeItem("jwToken");
            let codeVerifier = this.generateCodeVerifier();
            console.log("code:", codeVerifier);
            let codeChallengeValue = this.generateCodeChallenge(codeVerifier);
            console.log("api url ", this.apiURL)
            codeChallengeValue.then(value=>{
                console.log("code challenge value:", value);
                let body = {
                    codeChallenge: value
                }
                this.http.post(this.apiURL+'/LARF_Keycloak/getCodeforJWTokenMerchant',body,{ responseType: 'text'}).subscribe(data=>{
                    console.log("data from sails:", data)

                    var formData = {
                        codeVerifier:codeVerifier,
                        dbcode:data
                    }
                    this.http.post(this.apiURL+'/LARF_Keycloak/getJWTokenMerchantwithVerifyCode',formData,{ responseType: 'text'}).subscribe(data=>{
                        console.log("getaccess jwt data:",data);
                        // console.log("jwt data:",data['tokenS']);
                        let jsonToken = JSON.parse(data);
                        var jwtok = jsonToken.tokenS;
                        console.log("json jwt:",jwtok);
                        // let jwtValue = data;
                        localStorage.setItem("jwToken",jwtok);
                        // comment below one when working locally via login method, not in loading app from admin page
                        localStorage.removeItem("lb4b-token");
                        resolve();
                    })
                })
                
            })
 
        });
    }

    public generateCodeVerifier() {
        var returnValue = "";
        var randomByteArray = new Uint8Array(32);
        window.crypto.getRandomValues(randomByteArray);
    
        returnValue = this.base64urlencode(randomByteArray);
        return returnValue;
    
       
        //document.getElementById("codeVerifierValue").innerHTML = returnValue;
    }
    public base64urlencode(sourceValue) {
        var stringValue = String.fromCharCode.apply(null, sourceValue);
        var base64Encoded = btoa(stringValue);
        var base64urlEncoded = base64Encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
        return base64urlEncoded;
    }
    public async generateCodeChallenge(codeVerifier) {
        var codeChallengeValue = "";
    
    
        var textEncoder = new TextEncoder();
        var encodedValue = textEncoder.encode(codeVerifier);
        var digest = await window.crypto.subtle.digest("SHA-256", encodedValue);
    
        codeChallengeValue = this.base64urlencode(Array.from(new Uint8Array(digest)));
        
        return codeChallengeValue.toString();
       
       // document.getElementById("codeChallengeValue").innerHTML = codeChallengeValue;
    }
}
