/*import { Observer, Observable, of } from 'rxjs';
// import { Observable } from "rxjs/Observable";
// import {Observer} from 'rxjs/Observer';
// import {Observer} from 'rxjs/Rx';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
	observable: Observable<any>;
	observer: Observer<any>;
	constructor() {
		this.observable = Observable.create((observer:Observer<any>) => {
	      this.observer = observer;
	    }).share();
	}

	broadcast(event) {
	    this.observer.next(event);
	}

	on(eventName, callback) {
	    this.observable.filter((event) => {
	    	return event.name === eventName;
	    }).subscribe(callback);
	}
}
*/

import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
// import { Subject } from 'rxjs/Subject';
// import { Subscription } from 'rxjs/Subscription';
// import 'rxjs/add/operator/filter'
// import 'rxjs/add/operator/map'

interface Message {
  type: string;
  payload: any;
}

type MessageCallback = (payload: any) => void;

@Injectable()
export class BroadcastService {
	listeners;
  private handler = new Subject<Message>();
	constructor() {
		this.listeners = {};
	}

  broadcast(type: string, payload: any) {
    this.handler.next({ type, payload });
  }
  on(name, listener) {
  	console.log("in on function");
        if (!this.listeners[name]) {
            this.listeners[name] = [];
        }

        this.listeners[name].push(listener);
        /*console.log('outgoingMessage in myapps.iframecomm.direc.cntrl.ngPostMessage - 2', message);
	    var sender;
	    if (domain == null) {
	        domain = "*";
	    }
	    sender = window.parent;
	    var m = JSON.stringify({
	        status: 200,
	        message: message
	    });
	    return sender.postMessage(m, domain);*/
    }

  /*subscribe(type: string, callback: MessageCallback): Subscription {
    return this.handler
      .filter(message => message.type === type)
      .map(message => message.payload)
      .subscribe(callback);
  }*/
}