import { BrowserModule } from '@angular/platform-browser';
import { NgModule,APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
// import { StorageServiceModule } from 'ngx-webstorage-service';

import { RoutesModule } from './routes/routes.module';
import { AppComponent } from './app.component';
import { AppInitService } from './initservice/appinitservice';

export function initializeApp1(appInitService: AppInitService) {
    return (): Promise<any> => { 
      return appInitService.Init();
    }
  }
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // StorageServiceModule,
    RoutesModule,
    FormsModule
    
  ],
  providers: [AppInitService, { provide: APP_INITIALIZER,useFactory: initializeApp1, deps: [AppInitService], multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
