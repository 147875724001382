import { Component, OnInit, NgModule, HostListener,  ViewChild, ElementRef } from '@angular/core';
import * as THREE from 'three';
import * as _ from 'lodash';
import * as $ from "jquery";
import { HttpClient } from "@angular/common/http";
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Inject, Injectable } from '@angular/core';
import { APIGetFactoryService } from '../../services/get-factory.service';
import { APIPostFactoryService } from '../../services/post-factory.service';
import { BroadcastService } from '../../services/broadcast.service';

// import Stats from 'three/examples/jsm/libs/stats.module';
// import { GUI } from 'three/examples/jsm/libs/dat.gui.module';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { DragControls } from 'three/examples/jsm/controls/DragControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { DecalGeometry } from 'three/examples/jsm/geometries/DecalGeometry';

@Component({
  selector: 'app-virtual-art-gallery',
  providers: [BroadcastService],
  templateUrl: './virtual-art-gallery.component.html',
  styleUrls: ['./virtual-art-gallery.component.scss']
})
export class VirtualArtGalleryComponent implements OnInit {
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    if(event.target.innerWidth>event.target.innerHeight){
      document.getElementById('iconsOnTop').style.display = 'none';
      document.getElementById('instructions').style.display = 'none';
      document.getElementById('container').style.display = 'none';
      document.getElementById('inLandscapeMode').style.display = 'block';
      var doc = window.document;

      var cancelFullScreen = doc.exitFullscreen || doc['mozCancelFullScreen'] || doc['webkitExitFullscreen'] || doc['msExitFullscreen'] || doc['webkitEnterFullscreen'];
      cancelFullScreen.call(doc);
      location.reload();
    } else {
      document.getElementById('iconsOnTop').style.display = 'block';
      document.getElementById('instructions').style.display = 'block';
      document.getElementById('container').style.display = 'block';
      document.getElementById('inLandscapeMode').style.display = 'none';
      var doc = window.document;
      var docEl = doc.documentElement;
      var requestFullScreen = docEl.requestFullscreen || docEl['mozRequestFullScreen'] || docEl['webkitRequestFullscreen'] || docEl['msRequestFullscreen'] || docEl['webkitEnterFullscreen'] || docEl['webkitIsFullScreen'];
      requestFullScreen.call(docEl);
      $("#vagCanvas").css("height", '100vh').css("width", '100vw')
      location.reload();
    }
  }
  @HostListener('window:message',['$event'])
  onMessage(msgInfo){
    console.log("msgInfo is", msgInfo);
    if ((this.iFrameMsgCount < 3) && !_.has(msgInfo.data, 'message')){
      this.iFrameMsgCount = this.iFrameMsgCount + 1;
      this.getCartContent();
      this.getScreenWxH();
      this.getThemeDtl();
    } else if(_.has(msgInfo.data.message, 'cartValues')){
      // this.globalCartOverallData['totalPrice'] = (msgInfo.data.message.cartValues.totalPrice/100).toFixed(2);
      // this.globalCartOverallData['totalDiscount'] = (msgInfo.data.message.cartValues.totalDiscount/100).toFixed(2);
      // this.globalCartInfo = msgInfo.data.message.cartValues.cartFullContent;
      this.globalCartInfo = msgInfo.data.message.cartValues;
      this.totalCartQty = _.sum((_.flatten(_.map(msgInfo.data.message.cartValues.cartFullContent, 'qty'))).map(Number));
      console.log("this.globalCartInfo is", this.globalCartInfo);
      console.log("this.totalCartQty is", this.totalCartQty);
    } else if(_.has(msgInfo.data.message, 'windowDimension')){
      // windowDimension
      this.fullScreenWidth = msgInfo.data.message.windowDimension.width;
      this.fullScreenHeight = msgInfo.data.message.windowDimension.height;
    } else if(_.has(msgInfo.data.message, 'isDebutTheme')){
      // windowDimension
      this.debutTheme = msgInfo.data.message.isDebutTheme;
    } else if(_.has(msgInfo.data.message, 'screen')){
      if(msgInfo.data.message.screen.width>msgInfo.data.message.screen.height){
        document.getElementById('iconsOnTop').style.display = 'none';
        document.getElementById('instructions').style.display = 'none';
        document.getElementById('container').style.display = 'none';
        document.getElementById('inLandscapeMode').style.display = 'block';
        location.reload();
      } else {
        document.getElementById('iconsOnTop').style.display = 'block';
        document.getElementById('instructions').style.display = 'block';
        document.getElementById('container').style.display = 'block';
        document.getElementById('inLandscapeMode').style.display = 'none';
        location.reload();
      }
    }
    /*if (e.data.for=="user"){
      alert('here i am');
    }*/
  }
    
  renderer = new THREE.WebGLRenderer( { antialias: true } );
  scene = null;
  camera = null;
  mesh = null;
  raycaster = null;
  controls = null;
  selectedMesh = null;
  mouse = null;
  stats = null;
  intersects = [];
  intersection = null;
  mouseHelper = null;
  line : any;
  textureLoader = null;
  position = null;
  orientation = null;
  size = null;
  params = null;
  decalMaterial = null; 
  decals = null;
  elem : any;
  products : any = [];
  artProduct : string;
  art = {
    productName: "",
    productImage: "",
    productDescription: "",
    price: 0,
    product_variant_options: []
  };
  product_id : any;
  artProductSize : any;
  productName : string;
  productDescription : string;
  isArtInfo : boolean = false;
  dragObjects = [];
  isVagAvail = false;
  isStoreAvail = false;
  isCartVisible = false;
  totalCartQty = 0;
  // globalCartOverallData : any;
  globalCartInfo : any = {};
  iFrameMsgCount : number = 1;
  domain : string;
  platform :string;
  isCartBtnDisabled = false;
  isLoadbarEnable = false;
  storageProducts = [];
  storageProdIDs = [];
  currentProd;
  cartBtnName = 'Add to Cart';
  globalMerchantID;
  setCartValue;
  fullScreen: boolean = false;
  fullScreenWidth: number = 1300;
  fullScreenHeight: number = 670;
  debutTheme:boolean = false;
  upArrowClicks: boolean = false;
  downArrowClicks: boolean = false;
  leftArrowClicks: boolean = false;
  rightArrowClicks: boolean = false;

  //movement-pad
  regionData: any = {};
  handleData: any = {};
  newLeft:any;
  newTop:any;
  distance:any;
  angle: any;
  isMovementPadEnabled:boolean = false;
  isMousePadClicked:boolean = false;
  initialScreen: boolean = true;
  changeScreen: boolean = false;
  actScreen:boolean = false;


  constructor(@Inject(LOCAL_STORAGE) public storage: StorageService, private httpClient: HttpClient, private apiGetFactory : APIGetFactoryService, private apiPostFactory : APIPostFactoryService, private broadcastService: BroadcastService) {
    this.globalCartInfo['cartFullContent'] = [];

  	this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 20000 );
    this.raycaster = new THREE.Raycaster();
    this.mesh = {};
    this.renderer.domElement.id = 'vagCanvas';
    this.controls = new OrbitControls( this.camera, this.renderer.domElement );
    this.mouse = new THREE.Vector2();
    this.intersects = [];

    this.intersection = {
      intersects: false,
      point: new THREE.Vector3(),
      normal: new THREE.Vector3()
    };
    this.textureLoader = new THREE.TextureLoader();
    this.position = new THREE.Vector3();
    this.orientation = new THREE.Euler();
    this.size = new THREE.Vector3( 10, 10, 10 );
    this.selectedMesh = {};
    // this.moved = false;
    this.decals = [];
    this.params = {
      minScale: 30,
      maxScale: 30,
      rotate: false,
      clear: function () {

        // removeDecals();

      }
    };
    
        
  	// container = document.getElementById( 'container' );

  } // constructor close

  ngOnInit() {
    this.globalCartInfo['cartFullContent'] = [];
    
    if(window.innerWidth<window.innerHeight){
      document.getElementById('iconsOnTop').style.display = 'none';
      document.getElementById('instructions').style.display = 'none';
      document.getElementById('container').style.display = 'none';
      document.getElementById('inLandscapeMode').style.display = 'block';
    }
    var searchParams = window.location.search;
    console.log("searchParams are", searchParams);
    var inputParams = this.getParams(searchParams);
    var globalStorage = this.storage;
    console.log("inputParams are", inputParams);
    if((_.has(inputParams, 'uniqueUrlID') && !_.isEmpty(inputParams['uniqueUrlID'])) && (inputParams['uniqueUrlID'] == 'liveb4buy_myshopify' || inputParams['uniqueUrlID'] == 'amira-fine-art_myshopify')){
      /*this.isVagAvail = true;
      this.showroomLoaded(inputParams['uniqueUrlID']);
      var body = document.querySelector<HTMLElement>('body');
      body.style.overflow = 'hidden';*/
      this.apiGetFactory.customGet('LB4_Merchant', 'getMerchantInfoVagsite', inputParams).subscribe(async merchantInfo => {
        console.log("inside get merchant vagsite...")
        this.globalMerchantID = merchantInfo[0]['id'];
        await this.apiGetFactory.customGet('LARF_Keycloak', 'getKcTokenMerchant', { merchantId: merchantInfo[0]['id'] }).subscribe(data => {
          console.log("live-sec data from lb4b-api:", data);
          globalStorage.set('lb4b-token', JSON.stringify(data));
          localStorage.removeItem("jwToken");
          setInterval(async () => {
            var toBackend = {
              access_token: JSON.parse(this.storage.get('lb4b-token')).access_token,
              refresh_token: JSON.parse(this.storage.get('lb4b-token')).refresh_token
            }
            await this.apiGetFactory.customGet('LARF_Keycloak', 'refreshKcToken', toBackend).subscribe(data => {
              console.log("refresj live-sec data from lb4b-api:", data);
              console.log("before refresh", JSON.parse(globalStorage.get("lb4b-token")))
              globalStorage.set('lb4b-token', JSON.stringify(data));
              console.log("After refresh", JSON.parse(globalStorage.get("lb4b-token")))
            })
            // 1sec=1*1000 so 1min=60*1000 similarly 30mins=30*60=1800==>1800*1000 secs
          }, 1800 * 1000);
        
        this.domain = merchantInfo[0]['domain'];
        this.platform = merchantInfo[0]['platform'];
        // this.showroomLoaded(inputParams['uniqueUrlID']);
        this.isVagAvail = true;
        this.showroomLoaded(inputParams['uniqueUrlID']);
        var body = document.querySelector<HTMLElement>('body');
        body.style.overflow = 'hidden';
      });
      }, error => {
        console.log("error in getting merchant info", error);
        this.isStoreAvail = true;
      })
    } else {
      this.isStoreAvail = true;
    }


    var container = document.getElementById( 'container' );

    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( window.innerWidth, window.innerHeight );
    $("#vagCanvas").css("height", '100vh').css("width", '100vw')
    container.appendChild( this.renderer.domElement );

    // container.appendChild( this.stats.dom );
    // this.scene.background = new THREE.Color( 0xffffff );
    this.scene.background = new THREE.TextureLoader().load( "https://res.cloudinary.com/dxodr7sdd/image/upload/v1594116192/Amira%20Fine%20Art/skyBg.jpg" );
    
    this.camera.position.set(10, 250, 0);
    this.camera.lookAt(new THREE.Vector3(10, 250, 0));

    this.camera.target = new THREE.Vector3();
  
    this.controls.enableDamping = false; // an animation loop is required when either damping or auto-rotation are enabled
    this.controls.dampingFactor = 0.05;
    this.controls.target.set(10, 250, 0)
    this.controls.screenSpacePanning = false;
    this.controls.enableKeys = true;
    this.controls.minDistance = 100;
    this.controls.maxDistance = 100;
    this.controls.keys = {
      LEFT: 65, //A Move Left
      UP: 87, // W Move Forward
      RIGHT: 68, // D Move Right
      BOTTOM: 83 // S Move Backward
    }
    this.controls.keyPanSpeed = 35.0;
    this.controls.maxPolarAngle = Math.PI / 2;
    this.controls.minPolarAngle = Math.PI / 2;


    this.scene.add( new THREE.AmbientLight( 0xffffff, 0.5 ) );


    var light = new THREE.PointLight(0xffffff, 1.0, 1000);
    light.position.set(-50,600,50);
    light.castShadow = true;
    light.shadow.camera.near = 0.1;
    light.shadow.camera.far = 25;
    this.scene.add(light)

    var light2 = new THREE.PointLight(0xffffff, 1.5, 1000);
    light2.position.set(150,800,-150);
    light2.castShadow = true;
    light2.shadow.camera.near = 0.1;
    light2.shadow.camera.far = 25;
    this.scene.add(light2)

    // floor
    
    //floor

    var linegeometry = new THREE.BufferGeometry();
    linegeometry.setFromPoints( [ new THREE.Vector3(), new THREE.Vector3() ] );

    this.line = new THREE.Line( linegeometry, new THREE.LineBasicMaterial() );
    this.scene.add( this.line );

    this.mouseHelper = new THREE.Mesh( new THREE.BoxBufferGeometry( 0, 0, 0 ), new THREE.MeshNormalMaterial() );
    this.mouseHelper.visible = true;
    this.scene.add( this.mouseHelper );
    console.log("mouseHelper is 123", this.mouseHelper)

    var movementPad = $('.movement-pad');
    var region = $('.region');
    var handle = $('.handle');
    movementPad.css({
      top: $("#vagCanvas").height() - region.outerHeight() - 10,
      left: 20
    });
    var self1 = this;
    region.on("mousedown", function (event) {
      console.log("in mouse down", event);
      // this.mouseDown = true;
      handle.css("opacity", "1.0");
      self1.updatePosition(event.pageX, event.pageY);
    });
    region.on("touchstart", function (event) {
      console.log("in touch start");
      // mouseDown = true;
      handle.css("opacity", "1.0");
      self1.updatePosition(event.originalEvent.targetTouches[0].pageX, event.originalEvent.targetTouches[0].pageY);
    });
    this.regionData.width = region.outerWidth();
    this.regionData.height = region.outerHeight();
    this.regionData.position = region.position();
    this.regionData.offset = region.offset();
    this.regionData.radius = this.regionData.width / 2;
    this.regionData.centerX = this.regionData.position.left + this.regionData.radius;
    this.regionData.centerY = this.regionData.position.top + this.regionData.radius;
    this.handleData.width = handle.outerWidth();
    this.handleData.height = handle.outerHeight();
    this.handleData.radius = this.handleData.width / 2;

    this.regionData.radius = this.regionData.width / 2 - this.handleData.radius;

    var moved = false;
    this.controls.addEventListener( 'change', function () {
      console.log("here is change is working")
      moved = true;
    } );

    container.addEventListener( 'mousedown', function () {
  
      moved = false;

    }, false );

    container.addEventListener( 'touchstart', function (event) {
      console.log("evenet in container click", event);
      moved = false;

    }, false );

    container.addEventListener( 'mouseup', function (event) {
      if(event.target['className'] == 'region' || event.target['className'] == 'handle'|| event.target['className'] == 'act-region' || event.target['className'] == 'act-handle' || event.target['className'] == 'fs-region' || event.target['className'] == 'fs-handle'){
        self1.isMousePadClicked = true;
      } else {
        self1.isMousePadClicked = false;
      }
      // self1.checkIntersection(); 
      // console.log("click of this.intersects is", self1.intersects[0])

      if(event.button === 2){
        document.getElementById("ArtDetailsModal").style.display = 'none';
      }else if(!self1.isMousePadClicked && !moved && self1.selectedMesh.name.indexOf('Picture_') > -1){
        self1.isLoadbarEnable = true;
        var productID =  self1.selectedMesh.name.split('Picture_')[1];
        console.log("productID is", productID);
        self1.cartBtnName = 'Add to Cart';
        /*storageProducts : object;
        storageProdIDs : object;*/
        self1.storageProdIDs = self1.storage.get('storageProdIDs');
        self1.storageProducts = self1.storage.get('storageProducts');
        console.log("storageProd are", self1.storageProdIDs);
        if(self1.storageProdIDs){
          var existsProdIndex = _.indexOf(self1.storageProdIDs, productID);
          if(existsProdIndex > -1){
            self1.isLoadbarEnable = false;
            self1.isArtInfo = true;
            console.log("is artinfo", self1.isArtInfo)
            console.log("get id", document.getElementById("artDetailsModal"))
        
            var productDetailsModal = document.getElementById("artDetailsModal");
            productDetailsModal.style.display = "block";
            // var span = document.getElementsByClassName("close")[0];
            
            console.log("here is productInfo.products", self1.storageProducts[existsProdIndex])
            self1.currentProd = self1.storageProducts[existsProdIndex];
            self1.art['productName'] = self1.storageProducts[existsProdIndex]['platform_name'];
            self1.art['productImage'] = self1.storageProducts[existsProdIndex]['default_image'];
            self1.art['productDescription'] = self1.storageProducts[existsProdIndex]['description'].replace(/\\"/g, "”");
            self1.art['price'] = self1.storageProducts[existsProdIndex]['price'];
            self1.art['product_variant_options'] = self1.storageProducts[existsProdIndex]['product_variant_options'];
          } else {
            self1.product_info(productID);
          }
        } else {
          self1.storageProdIDs = [];
          self1.storageProducts = [];
          self1.product_info(productID);
        }
      } 
      // console.log("here is the mesh name", self1.selectedMesh.name)
    } );

    container.addEventListener( 'touchstart', function (event) {
      //console.log("event in touchstart is", event);
      if(event.target['className'] == 'region' || event.target['className'] == 'handle'|| event.target['className'] == 'act-region' || event.target['className'] == 'act-handle' || event.target['className'] == 'fs-region' || event.target['className'] == 'fs-handle'){
        self1.isMousePadClicked = true;
      } else {
        self1.isMousePadClicked = false;
      }
      moved = false;
      // self1.checkIntersection();
      if(!self1.isMousePadClicked && !moved && self1.selectedMesh.name.indexOf('Picture_') > -1){
        self1.isLoadbarEnable = true;
        var productID =  self1.selectedMesh.name.split('Picture_')[1];
        console.log("productID is", productID);
        self1.cartBtnName = 'Add to Cart';
        /*storageProducts : object;
        storageProdIDs : object;*/
        self1.storageProdIDs = self1.storage.get('storageProdIDs');
        self1.storageProducts = self1.storage.get('storageProducts');
        console.log("storageProd are", self1.storageProdIDs);
        if(self1.storageProdIDs){
          var existsProdIndex = _.indexOf(self1.storageProdIDs, productID);
          if(existsProdIndex > -1){
            self1.isLoadbarEnable = false;
            self1.isArtInfo = true;
            console.log("is artinfo", self1.isArtInfo)
            console.log("get id", document.getElementById("artDetailsModal"))
        
            var productDetailsModal = document.getElementById("artDetailsModal");
            productDetailsModal.style.display = "block";
            // var span = document.getElementsByClassName("close")[0];
            
            console.log("here is productInfo.products", self1.storageProducts[existsProdIndex])
            self1.currentProd = self1.storageProducts[existsProdIndex];
            self1.art['productName'] = self1.storageProducts[existsProdIndex]['platform_name'];
            self1.art['productImage'] = self1.storageProducts[existsProdIndex]['default_image'];
            // self1.art['width'] = self1.storageProducts[existsProdIndex]['dimensions'].stdUSDimensions.width;
            // self1.art['height'] = self1.storageProducts[existsProdIndex]['dimensions'].stdUSDimensions.height;
            // self1.art['depth'] = self1.storageProducts[existsProdIndex]['dimensions'].stdUSDimensions.length;
            // console.log("product info function sizes", self1.art)
            self1.art['productDescription'] = self1.storageProducts[existsProdIndex]['description'].replace(/\\"/g, "”");
            self1.art['price'] = self1.storageProducts[existsProdIndex]['price'];
            self1.art['product_variant_options'] = self1.storageProducts[existsProdIndex]['product_variant_options'];
          } else {
            self1.product_info(productID);
          }
        } else {
          self1.storageProdIDs = [];
          self1.storageProducts = [];
          self1.product_info(productID);
        }
      } 
    });

    this.onWindowResize();
    this.animate();
    // console.log("oninit first_art is", this.first_art)
    // this.selectedProduct();

  } //ngOnInit clocse

  updatePosition(pageX, pageY){
    this.newLeft = (pageX - this.regionData.offset.left);
    this.newTop = (pageY - this.regionData.offset.top);
    
    // If handle reaches the pad boundaries.
    this.distance = Math.pow(this.regionData.centerX - this.newLeft, 2) + Math.pow(this.regionData.centerY - this.newTop, 2);
    if (this.distance > Math.pow(this.regionData.radius, 2)) {
      this.angle = Math.atan2((this.newTop - this.regionData.centerY), (this.newLeft - this.regionData.centerX));
      this.newLeft = (Math.cos(this.angle) * this.regionData.radius) + this.regionData.centerX;
      this.newTop = (Math.sin(this.angle) * this.regionData.radius) + this.regionData.centerY;
    }
    this.newTop = Math.round(this.newTop * 10) / 10;
    this.newLeft = Math.round(this.newLeft * 10) / 10;

    $('.handle').css({
      top: this.newTop - this.handleData.radius,
      left: this.newLeft - this.handleData.radius
    });
    // console.log(this.newTop , this.newLeft);

    // Providing event and data for handling camera movement.
    var deltaX = this.regionData.centerX - parseInt(this.newLeft);
    var deltaY = this.regionData.centerY - parseInt(this.newTop);
    // Normalize x,y between -2 to 2 range.
    deltaX = -2 + (2+2) * (deltaX - (-this.regionData.radius)) / (this.regionData.radius - (-this.regionData.radius));
    deltaY = -2 + (2+2) * (deltaY - (-this.regionData.radius)) / (this.regionData.radius - (-this.regionData.radius));
    deltaX = Math.round(deltaX * 10) / 10;
    deltaY = Math.round(deltaY * 10) / 10;
    console.log("deltaX is", deltaX);
    console.log("deltaY is", deltaY);
    if(deltaY<-1){
      this.downArrowClicks = true;
    } else if(deltaX<-1){
      this.rightArrowClicks = true;
    } else if(deltaY>1){
      this.upArrowClicks = true;
    } else if(deltaX>1){
      this.leftArrowClicks = true;
    }
    this.moveFun();
    this.upArrowClicks = false;
    this.downArrowClicks = false;
    this.leftArrowClicks = false;
    this.rightArrowClicks = false;
    this.resetHandlePosition();

  }

  fsUpdatePosition(pageX, pageY){
    console.log("pageX in updatePosition is", pageX);
    console.log("pageY in updatePosition is", pageY);
    this.newLeft = (pageX - this.regionData.offset.left);
    this.newTop = (pageY - this.regionData.offset.top);
    
    // If handle reaches the pad boundaries.
    this.distance = Math.pow(this.regionData.centerX - this.newLeft, 2) + Math.pow(this.regionData.centerY - this.newTop, 2);
    if (this.distance > Math.pow(this.regionData.radius, 2)) {
      this.angle = Math.atan2((this.newTop - this.regionData.centerY), (this.newLeft - this.regionData.centerX));
      this.newLeft = (Math.cos(this.angle) * this.regionData.radius) + this.regionData.centerX;
      this.newTop = (Math.sin(this.angle) * this.regionData.radius) + this.regionData.centerY;
    }
    this.newTop = Math.round(this.newTop * 10) / 10;
    this.newLeft = Math.round(this.newLeft * 10) / 10;

    $('.fs-handle').css({
      top: this.newTop - this.handleData.radius,
      left: this.newLeft - this.handleData.radius
    });
    // console.log(this.newTop , this.newLeft);

    // Providing event and data for handling camera movement.
    var deltaX = this.regionData.centerX - parseInt(this.newLeft);
    var deltaY = this.regionData.centerY - parseInt(this.newTop);
    // Normalize x,y between -2 to 2 range.
    deltaX = -2 + (2+2) * (deltaX - (-this.regionData.radius)) / (this.regionData.radius - (-this.regionData.radius));
    deltaY = -2 + (2+2) * (deltaY - (-this.regionData.radius)) / (this.regionData.radius - (-this.regionData.radius));
    deltaX = Math.round(deltaX * 10) / 10;
    deltaY = Math.round(deltaY * 10) / 10;
    console.log("deltaX is", deltaX);
    console.log("deltaY is", deltaY);
    if(deltaY<-1){
      this.downArrowClicks = true;
    } else if(deltaX<-1){
      this.rightArrowClicks = true;
    } else if(deltaY>1){
      this.upArrowClicks = true;
    } else if(deltaX>1){
      this.leftArrowClicks = true;
    }
    /*console.log("this.downArrowClicks ", this.downArrowClicks)
    console.log("this.rightArrowClicks ", this.rightArrowClicks)
    console.log("this.upArrowClicks ", this.upArrowClicks)
    console.log("this.leftArrowClicks ", this.leftArrowClicks)*/
    this.moveFun();
    this.upArrowClicks = false;
    this.downArrowClicks = false;
    this.leftArrowClicks = false;
    this.rightArrowClicks = false;
    this.fsResetHandlePosition();

  }

  actUpdatePosition(pageX, pageY){
    console.log("pageX in updatePosition is", pageX);
    console.log("pageY in updatePosition is", pageY);
    this.newLeft = (pageX - this.regionData.offset.left);
    this.newTop = (pageY - this.regionData.offset.top);
    
    // If handle reaches the pad boundaries.
    this.distance = Math.pow(this.regionData.centerX - this.newLeft, 2) + Math.pow(this.regionData.centerY - this.newTop, 2);
    if (this.distance > Math.pow(this.regionData.radius, 2)) {
      this.angle = Math.atan2((this.newTop - this.regionData.centerY), (this.newLeft - this.regionData.centerX));
      this.newLeft = (Math.cos(this.angle) * this.regionData.radius) + this.regionData.centerX;
      this.newTop = (Math.sin(this.angle) * this.regionData.radius) + this.regionData.centerY;
    }
    this.newTop = Math.round(this.newTop * 10) / 10;
    this.newLeft = Math.round(this.newLeft * 10) / 10;

    $('.act-handle').css({
      top: this.newTop - this.handleData.radius,
      left: this.newLeft - this.handleData.radius
    });
    // console.log(this.newTop , this.newLeft);

    // Providing event and data for handling camera movement.
    var deltaX = this.regionData.centerX - parseInt(this.newLeft);
    var deltaY = this.regionData.centerY - parseInt(this.newTop);
    // Normalize x,y between -2 to 2 range.
    deltaX = -2 + (2+2) * (deltaX - (-this.regionData.radius)) / (this.regionData.radius - (-this.regionData.radius));
    deltaY = -2 + (2+2) * (deltaY - (-this.regionData.radius)) / (this.regionData.radius - (-this.regionData.radius));
    deltaX = Math.round(deltaX * 10) / 10;
    deltaY = Math.round(deltaY * 10) / 10;
    console.log("deltaX is", deltaX);
    console.log("deltaY is", deltaY);
    if(deltaY<-1){
      this.downArrowClicks = true;
    } else if(deltaX<-1){
      this.rightArrowClicks = true;
    } else if(deltaY>1){
      this.upArrowClicks = true;
    } else if(deltaX>1){
      this.leftArrowClicks = true;
    }
    /*console.log("this.downArrowClicks ", this.downArrowClicks)
    console.log("this.rightArrowClicks ", this.rightArrowClicks)
    console.log("this.upArrowClicks ", this.upArrowClicks)
    console.log("this.leftArrowClicks ", this.leftArrowClicks)*/
    this.moveFun();
    this.upArrowClicks = false;
    this.downArrowClicks = false;
    this.leftArrowClicks = false;
    this.rightArrowClicks = false;
    this.actResetHandlePosition();

  }
  resetHandlePosition () {
    // var handles = $('<div class="handle"></div>')
    $('.handle').animate({
      top: this.regionData.centerY - this.handleData.radius,
      left: this.regionData.centerX - this.handleData.radius,
      opacity: 0.1
    }, "fast");
  }
  fsResetHandlePosition () {
    // var handles = $('<div class="handle"></div>')
    $('.fs-handle').animate({
      top: this.regionData.centerY - this.handleData.radius,
      left: this.regionData.centerX - this.handleData.radius,
      opacity: 0.1
    }, "fast");
  }
  actResetHandlePosition () {
    // var handles = $('<div class="handle"></div>')
    $('.act-handle').animate({
      top: this.regionData.centerY - this.handleData.radius,
      left: this.regionData.centerX - this.handleData.radius,
      opacity: 0.1
    }, "fast");
  }

moveFun(){
  console.log("this.upArrowClicks in moveFun is", this.camera.position)
    if(this.upArrowClicks){
      this.camera.translateZ(-50);
      // this.camera.getWorldDirection(this.position);
      // this.camera.position.addScaledVector( this.position, 1.0 );
      this.controls.target.set(this.camera.position.x, this.camera.position.y, this.camera.position.z);
    }
    if(this.downArrowClicks){
      this.camera.translateZ(50);
      this.controls.target.set(this.camera.position.x, this.camera.position.y, this.camera.position.z);
      // this.controls.update()

      
    }
    if(this.leftArrowClicks){
      this.camera.translateX(-50);
      this.controls.target.set(this.camera.position.x, this.camera.position.y, this.camera.position.z);
      // this.controls.update()

      
    }
    if(this.rightArrowClicks){
      this.camera.translateX(50);
      this.controls.target.set(this.camera.position.x, this.camera.position.y, this.camera.position.z);
      // this.controls.update()

      
    }
}

ngAfterViewInit(){

}

showroomLoaded(uniqueUrlID){
    var geometry = new THREE.PlaneGeometry( 5000, 5000, 10, 10 );
    geometry.rotateX( - Math.PI / 2 );
    var floorTexture = new THREE.TextureLoader().load( "https://res.cloudinary.com/dxodr7sdd/image/upload/v1600706965/LIVEARf/virtual-gallery/Showroom-design2_room_7_38.jpg" );
    floorTexture.wrapS = floorTexture.wrapT = THREE.RepeatWrapping;
    floorTexture.repeat.set(10, 10);
    var material = new THREE.MeshBasicMaterial( { map: floorTexture } );
    console.log('here is the material', material)
    var floorMesh = new THREE.Mesh( geometry, material );
    this.scene.add( floorMesh );

    var onProgress = function ( xhr ) {
      if ( xhr.lengthComputable ) {
        var percentComplete = xhr.loaded / xhr.total * 100;
        console.log( Math.round( percentComplete) + '% downloaded' );
      }
    };
 
    var onError = function () { };

    var manager = new THREE.LoadingManager();
    var self  = this;

 
      new MTLLoader( manager )
      .setPath( 'assets/models/LIVE-VUE-Amira/' )
      .load( 'LIVE-VUE-Amira-Showroom.mtl', function ( materials ) {
        console.log("meterial is here", materials)
        materials.preload();
        var selectedObjFile;
        if(uniqueUrlID == 'amira-fine-art_myshopify'){
          selectedObjFile = 'LIVE-VUE-Amira-Showroom.obj';
        } else if(uniqueUrlID == 'liveb4buy_myshopify'){
          selectedObjFile = 'LIVE-VUE-Acumen-Showroom.obj';          
        }
        new OBJLoader( manager )
          .setMaterials( materials )
          .setPath( 'assets/models/LIVE-VUE-Amira/' )
          .load( selectedObjFile, function ( object ) {
            console.log("object is meshmodel", object)
            self.mesh = object;
            console.log("here we are after mesh assign", self.mesh);
            object.position.x += 350;
            object.position.z -= 950;
            object.position.y = 5;
            object.rotation.y = -Math.PI/2;
            self.scene.add(object);

          }, onProgress, onError );
        console.log("onError is", onError)
      


      } );
  // this.broadcastService.broadcast('iframeLoaded', 'iframeLoaded');
  var cartContent = this.getCartContent();
  console.log("cartContent is ", cartContent);
}

getCartContent(){
  console.log("in getCartContent");
  var message = 'iframeLoaded';
  // console.log('outgoingMessage in myapps.iframecomm.direc.cntrl.ngPostMessage - 2', message);
  var sender;
  var domain = null;
  if (domain == null) {
      domain = "*";
  }
  sender = window.parent;
  var m = JSON.stringify({
      status: 200,
      message: message
  });
  console.log("m isisi", m)
  console.log("window isisi", window);
  return sender.postMessage(m, domain);
}

getScreenWxH(){
  console.log("in getScreenWxH");
  var message = 'getWindowWxH';
  // console.log('outgoingMessage in myapps.iframecomm.direc.cntrl.ngPostMessage - 2', message);
  var sender;
  var domain = null;
  if (domain == null) {
      domain = "*";
  }
  sender = window.parent;
  var m = JSON.stringify({
      status: 200,
      message: message
  });
  console.log("m isisi", m)
  console.log("window isisi", window);
  return sender.postMessage(m, domain);
}
getThemeDtl(){
  console.log("in getThemeDtl");
  var message = 'getThemeDtl';
  // console.log('outgoingMessage in myapps.iframecomm.direc.cntrl.ngPostMessage - 2', message);
  var sender;
  var domain = null;
  if (domain == null) {
      domain = "*";
  }
  sender = window.parent;
  var m = JSON.stringify({
      status: 200,
      message: message
  });
  console.log("m isisi", m)
  console.log("window isisi", window);
  return sender.postMessage(m, domain);
}

onContainerMove(event) {
	var x, y;
	// console.log("mouse move event is", event)
	if ( event.changedTouches ) {

	  x = event.changedTouches[ 0 ].pageX;
	  y = event.changedTouches[ 0 ].pageY;

	} else {

	  x = event.clientX;
	  y = event.clientY;

	}
	this.mouse.x = ( x / window.innerWidth ) * 2 - 1;
	this.mouse.y = - ( y / window.innerHeight ) * 2 + 1;

	// console.log("X value is", x)
	// console.log("Y value is", y)

	this.checkIntersection();
}

checkIntersection() {
  // console.log("here is the mesh", this.mesh)
  if ( ! this.mesh ) return;

    this.raycaster.setFromCamera( this.mouse, this.camera );
    // console.log("here is the mousePoint", this.mouse)
    // console.log("this.intersects before is", this.intersects)
    this.raycaster.intersectObject( this.mesh, true, this.intersects );
    // console.log("Mesh is", this.mesh)
    // console.log("this.intersects is", this.intersects[0])
    if ( this.intersects.length > 0 ) {
      var p = this.intersects[ 0 ].point;
      // console.log("here is the cursorPoint", p)
      // console.log("mouseHelper is working", this.mouseHelper)
      this.mouseHelper.position.copy( p );
      // console.log("mouseHelper is poisition is 123", this.mouseHelper.position.copy( p ))

      this.intersection.point.copy( p );

      var n = this.intersects[ 0 ].face.normal.clone();
      // console.log("mesh.matrixWorld", this.mesh['matrixWorld'])
      n.transformDirection( this.mesh['matrixWorld'] );
      n.multiplyScalar( 10 );
      n.add( this.intersects[ 0 ].point );

      this.intersection.normal.copy( this.intersects[ 0 ].face.normal );
      // console.log("here is the mouseHelper lookat", n)
      this.mouseHelper.lookAt( n );

      var positions = this.line.geometry.attributes.position;
      // console.log("here is the line poisiton", positions)
      positions.setXYZ( 0, p.x, p.y, p.z );
      positions.setXYZ( 1, n.x, n.y, n.z );
      // console.log("P value", p.x, p.y, p.z)
      // console.log("N value", n.x, n.y, n.z)
      positions.needsUpdate = true;
      this.intersection.intersects = true;
      this.selectedMesh = this.intersects[0].object;
      // console.log("intersects is find name", this.intersects[0].object.name)
      // console.log("mousePointer is", document.getElementById('container'))
      if(this.selectedMesh.name.indexOf('Picture_') > -1){
        console.log("intersects is product name", this.intersects[0].object.name)
        document.getElementById('container').style.cursor = 'pointer';
      } else if (this.selectedMesh.name.indexOf('wall_') > -1){
        document.getElementById('container').style.cursor = 'inherit';
      }

      this.intersects.length = 0;

    }
    else {

      this.intersection.intersects = false;

    }
  
    // console.log("intersection function ended")
  }


  product_info(id){
    console.log("in product_info")
    this.apiGetFactory.customGet('pd_product_info', 'getIndividualProduct', {productID:id, merchantID:this.globalMerchantID}).subscribe(productInfo=>{
    // this.apiGetFactory.customGet('pd_product_info', 'getIndividualProduct', {productID:id, merchantID:'5efd0600bba31f0010016a01'}).subscribe(productInfo=>{
      this.isLoadbarEnable = false;
      this.currentProd = productInfo
      this.storageProdIDs.push(id);
      this.storageProducts.push(productInfo);
      this.storage.set('storageProdIDs', this.storageProdIDs);
      this.storage.set('storageProducts', this.storageProducts);
      /*this.storageProdIDs = this.storage.get('storageProdIDs');
      this.storageProducts = this.storage.get('storageProducts');*/
      this.isArtInfo = true;
  
      var productDetailsModal = document.getElementById("artDetailsModal");
      productDetailsModal.style.display = "block";
      // var span = document.getElementsByClassName("close")[0];
      
      console.log("here is productInfo.products", productInfo)
      this.art['productName'] = productInfo['platform_name'];
      this.art['productImage'] = productInfo['default_image'];
      this.art['productDescription'] = productInfo['description'].replace(/\\"/g, "”");
      this.art['price'] = productInfo['price'];
      this.art['product_variant_options'] = productInfo['product_variant_options'];
    }, error =>{
      console.log("error in getting merchant info", error);
    })
    

 }


  async addToCart(cartValue){
    console.log("cartValue is", cartValue);
    this.cartBtnName = 'Adding to Cart';
    this.isCartBtnDisabled = true;
    console.log("addToCart clicked");
    var addedCartInfo = await this.pushMsgToAddCart(cartValue)
    this.cartBtnName = 'Added Successfully';
    console.log("addedCartInfo is", addedCartInfo);
    this.isCartBtnDisabled = false;
    console.log("after return is");
    this.getCartContent();
  }
  pushMsgToAddCart(cartValue){
    console.log("in pushMsgToAddCart");
    var message = 'cartValues|'.concat(this.currentProd['platform_product_id']+"|"+cartValue+"|"+this.currentProd['platform_name']+"|"+this.currentProd['variation_id']);
    console.log('outgoingMessage in myapps.iframecomm.direc.cntrl.ngPostMessage - 2', message);
      var sender;
      var domain = null;
      if (domain == null) {
          domain = "*";
      }
      sender = window.parent;
      var m = JSON.stringify({
          status: 200,
          message: message
      });
      console.log("m in pushMsgToAddCart is", m);
      return sender.postMessage(m, domain);
  }

  closeModal(){
    // var modal = document.getElementById("myModal");
    var productDetailsModal = document.getElementById("artDetailsModal");
    var cartContentModel = document.getElementById("cartContentID");
    // modal.style.display = "none";
    cartContentModel.style.display = "none";
    productDetailsModal.style.display = "none";
  }

  onWindowResize() {
    console.log("here is the windowresize")
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize( window.innerWidth, window.innerHeight );

  }


  animate() {
    // requestAnimationFrame( this.animate );
    window.requestAnimationFrame(() => this.animate());
    this.renderer.render( this.scene, this.camera );
    // this.stats.update();
  }

  openFullscreen(){
    document.getElementById('instructions').style.display = 'none';
    this.isMovementPadEnabled = true;
  }

  getParams = function(searchUrl) {

    var str = searchUrl;
    var objURL = {};
    console.log('str replace', str);
    if (str){
        str.replace(
            new RegExp("([^?=&]+)(=([^&]*))?", "g"),
            function($0, $1, $2, $3) {
                objURL[$1] = $3;
            }
        );
    }
    console.log('objURL ', objURL);
    return objURL;
  }

  viewCart(){
    console.log("view cart clicked");
    this.isCartVisible = true;

    var cartDetailsModal = document.getElementById("cartContentID");
    cartDetailsModal.style.display = "block";
  }
  goToCart(){
    // this.domain = "liveb4buy.myshopify.com";
    // this.platform = "Shopify";
    // window.top.location.href = '//'.concat(this.domain, '/cart.php');
    switch (this.platform) {
      case 'Bigcommerce':
        window.top.location.href = '//'.concat(this.domain, '/cart.php');
        break;
      case 'Shopify':
        window.top.location.href = '//'.concat(this.domain, '/cart');
        break;
      default :
        console.log('UnKnown-Platform', this.platform)
        break;
    }
  }
  optionChanged(selectedOption){
    console.log("selectedOption in optionChanged is", selectedOption);
  }
  toggleScreen(){
    var toggleSelf = this;
    var doc = window.document;
    var docEl = doc.documentElement;
    // var requestFullScreen = docEl.requestFullscreen || docEl['mozRequestFullScreen'] || docEl['webkitRequestFullScreen'] || docEl['msRequestFullscreen'] || docEl['webkitEnterFullscreen'];
    var requestFullScreen = docEl.requestFullscreen || docEl['mozRequestFullScreen'] || docEl['webkitRequestFullscreen'] || docEl['msRequestFullscreen'] || docEl['webkitEnterFullscreen'] || docEl['webkitIsFullScreen'];
    var cancelFullScreen = doc.exitFullscreen || doc['mozCancelFullScreen'] || doc['webkitExitFullscreen'] || doc['msExitFullscreen'] || doc['webkitEnterFullscreen'] || doc['webkitIsFullScreen'];
    if(!doc.fullscreenElement && !doc['mozFullScreenElement'] && !doc['webkitFullscreenElement'] && !doc['msFullscreenElement'] && !doc['webkitEnterFullscreen'] && !doc['webkitIsFullScreen']) {
      this.fullScreen = true;
      requestFullScreen.call(docEl);
      $("#vagCanvas").css("height", '100vh').css("width", '100vw')
      // this.isFullScreen = true;
      this.initialScreen = false;
      this.actScreen = false;
      setTimeout(function(){ 
        var movementPad = $('.fs-movement-pad');
        var fsRegion = $('.fs-region');
        var fsHandle = $('.fs-handle');
        movementPad.css({
          top: $("#vagCanvas").height() - fsRegion.outerHeight() - 10,
          left: 20
        });
        toggleSelf.regionData.width = fsRegion.outerWidth();
        toggleSelf.regionData.height = fsRegion.outerHeight();
        toggleSelf.regionData.position = fsRegion.position();
        toggleSelf.regionData.offset = fsRegion.offset();
        toggleSelf.regionData.radius = toggleSelf.regionData.width / 2;
        toggleSelf.regionData.centerX = toggleSelf.regionData.position.left + toggleSelf.regionData.radius;
        toggleSelf.regionData.centerY = toggleSelf.regionData.position.top + toggleSelf.regionData.radius;
        toggleSelf.handleData.width = fsHandle.outerWidth();
        toggleSelf.handleData.height = fsHandle.outerHeight();
        toggleSelf.handleData.radius = toggleSelf.handleData.width / 2;

        toggleSelf.regionData.radius = toggleSelf.regionData.width / 2 - toggleSelf.handleData.radius;

        fsRegion.on("mousedown", function (event) {
          console.log("in mouse down in fsRegion", event);
          // toggleSelf.mouseDown = true;
          fsHandle.css("opacity", "1.0");
          toggleSelf.fsUpdatePosition(event.pageX, event.pageY);
        });
        fsRegion.on("touchstart", function (event) {
          console.log("in touch start in fsRegion");
          // mouseDown = true;
          fsHandle.css("opacity", "1.0");
          toggleSelf.fsUpdatePosition(event.originalEvent.targetTouches[0].pageX, event.originalEvent.targetTouches[0].pageY);
        });

      }, 200);
    }
    else {
      cancelFullScreen.call(doc);
      // this.isFullScreen = false;
      this.initialScreen = false;
      this.fullScreen = false;
      this.actScreen = true;
      setTimeout(function(){ 
        var actMovementPad = $('.act-movement-pad');
        var actRegion = $('.act-region');
        var actHandle = $('.act-handle');
        actMovementPad.css({
          top: $("#vagCanvas").height() - actRegion.outerHeight() - 10,
          left: 20
        });
        toggleSelf.regionData.width = actRegion.outerWidth();
        toggleSelf.regionData.height = actRegion.outerHeight();
        toggleSelf.regionData.position = actRegion.position();
        toggleSelf.regionData.offset = actRegion.offset();
        toggleSelf.regionData.radius = toggleSelf.regionData.width / 2;
        toggleSelf.regionData.centerX = toggleSelf.regionData.position.left + toggleSelf.regionData.radius;
        toggleSelf.regionData.centerY = toggleSelf.regionData.position.top + toggleSelf.regionData.radius;
        toggleSelf.handleData.width = actHandle.outerWidth();
        toggleSelf.handleData.height = actHandle.outerHeight();
        toggleSelf.handleData.radius = toggleSelf.handleData.width / 2;

        toggleSelf.regionData.radius = toggleSelf.regionData.width / 2 - toggleSelf.handleData.radius;

        actRegion.on("mousedown", function (event) {
          console.log("in mouse down in actRegion", event);
          // toggleSelf.mouseDown = true;
          actHandle.css("opacity", "1.0");
          toggleSelf.actUpdatePosition(event.pageX, event.pageY);
        });
        actRegion.on("touchstart", function (event) {
          console.log("in touch start in actRegion");
          // mouseDown = true;
          actHandle.css("opacity", "1.0");
          toggleSelf.actUpdatePosition(event.originalEvent.targetTouches[0].pageX, event.originalEvent.targetTouches[0].pageY);
        });
      }, 200);
    }
  }



}
