import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class APIPostFactoryService {

	apiURL :string = environment.apiEndPoint;

	constructor(private http: HttpClient) { }

	public customPost (tableName:string, controlMethod:string, data:any){
		console.log("tableName is", tableName);
		console.log("controlMethod is", controlMethod);
		console.log("data is", data);
		if (localStorage.getItem('jwToken')&&!localStorage.get('lb4b-token')) {
			console.log("inside get pkce-jwt from local storage to pass it as a header...")
			console.log("pkce-jwt:",localStorage.getItem('jwToken'))
			const headers = new HttpHeaders({
				'content-type' : 'applicaton/json',
				'authorization': 'Bearer '+ localStorage.getItem('jwToken')
			  })
			  let options = { headers: headers };
			return this.http.post(`${this.apiURL}/${tableName}/${controlMethod}`, data, options)	
		}
		if (localStorage.get('lb4b-token')&&localStorage.get('lb4b-token')!='null') {
			console.log("inside get token from local storage to pass it as a header...")
			let t = atob(JSON.parse(JSON.parse(localStorage.get('lb4b-token'))['access_token']).split('.')[1])
			console.log(JSON.parse(t))
			t = JSON.parse(t)["iss"].split("/")
			console.log(t)
			let token = JSON.parse(JSON.parse(localStorage.get('lb4b-token'))).access_token
			console.log("kc_acc_tok-from_storage:", token);
			const headers = new HttpHeaders({
				'content-type': 'applicaton/json',
				'authorization': 'Bearer ' + token,
				'realmName': t[t.length - 1]
			})
			let options = { headers: headers };
			return this.http.post(`${this.apiURL}/${tableName}/${controlMethod}`, data, options)
		}
	    return this.http.post(`${this.apiURL}/${tableName}/${controlMethod}`, data)
	}

}
